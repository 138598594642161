import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import AboutHero from "../components/Sections/About/Hero"
import WhoWeAre from "../components/Sections/About/WhoWeAre"
import Coaches from "../components/Sections/About/Coaches"
import Process from "../components/Sections/About/Process"
import CTA from "../components/Sections/CTA"
import FAQ from "../components/Sections/About/FAQ"
import ogImage from "../assets/images/og_image.png"
const query = graphql`
  {
    wordpressPage(template: { eq: "page-about.php" }) {
      acf {
        seo_title
        seo_description
        hero_header {
          part_i
          part_ii
        }
        hero_title
        hero_text
        hero_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 470, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }

        who_we_are_text
        who_we_are_title

        coaches {
          description
          name
          photo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 470, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }

        process_title
        process_text
        process_steps_title
        process_steps {
          text
          title
        }
        process_cta_title
        process_cta_text
        process_cta_mail

        faq {
          answer
          question
        }

        cta_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        cta_text
        cta_button {
          text
        }
      }
    }
  }
`

const AboutPage = () => {
  const data = useStaticQuery(query)
  const { seo_title, seo_description } = data.wordpressPage.acf

  const {
    hero_header,
    hero_image,
    hero_title,
    hero_text,
  } = data.wordpressPage.acf
  const { who_we_are_title, who_we_are_text } = data.wordpressPage.acf
  const { coaches } = data.wordpressPage.acf
  const {
    process_title,
    process_text,
    process_steps_title,
    process_steps,
    process_cta_title,
    process_cta_text,
    process_cta_mail,
  } = data.wordpressPage.acf
  const { cta_image, cta_text, cta_button } = data.wordpressPage.acf
  const { faq } = data.wordpressPage.acf

  return (
    <Layout isDark="true">
      <SEO image={ogImage} title={seo_title} description={seo_description} />

      <AboutHero
        header={hero_header}
        image={hero_image}
        title={hero_title}
        text={hero_text}
      />
      <WhoWeAre header={who_we_are_title} text={who_we_are_text} />
      <Coaches coaches={coaches} />
      <Process
        header={process_title}
        text={process_text}
        stepsTitle={process_steps_title}
        steps={process_steps}
        CTAtitle={process_cta_title}
        CTAtext={process_cta_text}
        CTAmail={process_cta_mail}
      />
      <FAQ faq={faq} />
      <CTA image={cta_image} text={cta_text} buttonText={cta_button.text} />
    </Layout>
  )
}

export default AboutPage
