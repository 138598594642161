import React from "react"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

import stroke from "../../../../assets/images/stroke-faq.svg"

class FAQ extends React.Component {

  constructor(props) {
    super(props);
    this.state = { expanded: 0 };
  }

  changeExpanded(i) {
  	this.setState({ expanded: i });
  }

  render() {

  	const { faq } = this.props;

  	const renderQuestions = () => {

	    const content = faq.map((item, i) => (
	        <Card key={i}>
	          <Accordion.Toggle as={Card.Header} eventKey={String(i)} onClick={() => this.changeExpanded(i)} className={this.state.expanded === i ? 'active' : null}>
	            <span className="title"><span className="number">{i < 9 ? '0' : null}{i + 1}</span>{ item.question }</span><span className="more">read more</span>
	          </Accordion.Toggle>
	          <Accordion.Collapse eventKey={String(i)}>
	            <Card.Body>{ item.answer }</Card.Body>
	          </Accordion.Collapse>
	        </Card>
	    ))
	    
	    return(
	      <Accordion defaultActiveKey="0">
	        { content }
	      </Accordion>
	    )
	  }

	  return (
	    <section className="section section--faq">
    	  <img src={stroke} className="stroke"/>
	      <div className="wrapper">
	        <h2 className="col-title">FAQ</h2>
	        <div className="col-faq">
	          { renderQuestions() }
	        </div>
	      </div>
	    </section>
	  )
	}
}

export default FAQ
