import React from "react"
import Img from "gatsby-image"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

import shapeLeft from "../../../../assets/images/about-hero-shape-left.svg"
import shapeRight from "../../../../assets/images/about-hero-shape-right.svg"

const AboutHero = ({ header, image, title, text }) => {

  return (
    <section className="section section--about-hero">
      <img src={shapeLeft} className="bg-shape bg-shape--left"/>
      <img src={shapeRight} className="bg-shape bg-shape--right"/>
      <div className="wrapper">
        <h2 className="hero-header"><span>{ header.part_i }</span><br/><span className="oblique">{ header.part_ii }</span></h2>
        <div className="about-hero-content">
          <div className="col-img">
            {image && (
            <Img
              className="hero-image"
              fluid={image.localFile.childImageSharp.fluid}
            />
            )}
          </div>
          <div className="col-text">
            <h3 className="hero-title">{ title }</h3>
            <p className="hero-text" dangerouslySetInnerHTML={{ __html: text }}></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutHero
