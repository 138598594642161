import React from "react"
import Carousel from 'react-bootstrap/Carousel'
import Img from "gatsby-image"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

import arrow from "../../../../assets/images/coaches-arrow.svg"
import stroke from "../../../../assets/images/stroke-coaches.svg"


class Coaches extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      index: 0,
      interval: setInterval(() => {
        const next = this.state.index >= props.coaches.length - 1 ? 0 : this.state.index + 1;
        this.setState({ index: next });
      }, 6000)
    };
  }

  render() {

    const { coaches } = this.props;

    const nextSlide = () => {
      clearInterval(this.state.interval);
      this.setState({ interval: 
        setInterval(() => {
          const next = this.state.index >= coaches.length - 1 ? 0 : this.state.index + 1;
          this.setState({ index: next });
        }, 6000)
      });
      const next = this.state.index >= coaches.length - 1 ? 0 : this.state.index + 1;
      this.setState({ index: next });
    }

    const renderImageSlider = () => {

      const content = coaches.map((item, i) => (
          <Carousel.Item key={i} className="coach">
            <h2 className="coach__name">{ item.name }</h2>
            {item.photo && (
            <Img
              className="coach__photo"
              fluid={item.photo.localFile.childImageSharp.fluid}
            />
            )}
          </Carousel.Item>
      ))
      
      return(
        <Carousel
          activeIndex={this.state.index}
          className="coaches-image-slider"
          fade={true}
          indicators={false}
          controls={false}
          interval={null}
        >
          { content }
        </Carousel>
      )
    }

    const renderDescriptionSlider = () => {

      const content = coaches.map((item, i) => (
          <Carousel.Item key={i} className="coach">
            <p className="coach__name">{ item.name }</p>
            <p className="coach__description">{ item.description }</p>
            {coaches.length > 1 &&
              <button onClick={() => nextSlide()} className="coach__arrow"><img src={arrow}/></button>
            }
          </Carousel.Item>
      ))
      
      return(
        <Carousel
          activeIndex={this.state.index}
          className="coaches-description-slider"
          fade={true}
          indicators={false}
          controls={false}
          interval={null}
        >
          { content }
        </Carousel>
      )
    }

    return (
      <section className="section section--coaches">
        <div className="wrapper">
          <img src={stroke} className="stroke"/>
          { renderImageSlider() }
          { renderDescriptionSlider() }
        </div>
      </section>
    )
  }

}

export default Coaches
