import React from "react"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

const WhoWeAre = ({ header, text }) => {

  return (
    <section className="section section--who-we-are">
      <div className="wrapper">
        <h2 className="section--who-we-are__header">{ header }</h2>
        <p className="section--who-we-are__text">{ text }</p>
      </div>
    </section>
  )
}

export default WhoWeAre
