import React from "react"

// eslint-disable-next-line no-unused-vars
import style from "./style.scss"

import shapeLeft from "../../../../assets/images/about-process-left.svg"
import shapeRightTop from "../../../../assets/images/about-process-right-top.svg"
import shapeRightBottom from "../../../../assets/images/about-process-right-bottom.svg"

const Process = ({ header, text, stepsTitle, steps, CTAtitle, CTAtext, CTAmail }) => {

	const renderSteps = () => {

		const content = steps.map((item, i) => (
			<li key={i}>
				<h3 className="process-list__title"><span className="number">{i < 9 ? '0' : null}{i + 1}</span><span>{ item.title }</span></h3>
				<p className="process-list__text">{ item.text }</p>
			</li>
		))

		return(
		  <ul className="process-list">
		    { content }
		  </ul>
		)
	}

	return (
		<section className="section section--process">
		<img src={shapeLeft} className="bg-shape bg-shape--left"/>
		<img src={shapeRightTop} className="bg-shape bg-shape--right-top"/>
		<img src={shapeRightBottom} className="bg-shape bg-shape--right-bottom"/>
		  <div className="wrapper">
		    <h2 className="section--process__header">{ header }</h2>
		    <p className="section--process__text">{ text }</p>
		    <div className="section--process__process">
		    	<div className="col-process-title">
		    		<h2 className="process-title">{ stepsTitle }</h2>
		    	</div>
		    	<div className="col-process-list">
		    		{ renderSteps() }
		    	</div>
		    </div>
		    <div className="section--process__cta">
		    	<h2 className="cta-title">{ CTAtitle }</h2>
		    	<p className="cta-text">{ CTAtext }</p>
		    	<a href={`mailto:${CTAmail}`} className="cta-mail">{ CTAmail }</a>
		    </div>
		  </div>
		</section>
	)
}

export default Process
